<template>
  <div class="table-container">
    <template v-if="chartdata[students_data_case].datasets">
      <div>
        <div class="change-data-btn">
          Incorporar estudiantes sin contestar instrumento
          <b-form-checkbox
            class="ml-1"
            v-model="students_data_status"
            switch
            @change="changeStudentsData"
          ></b-form-checkbox>
        </div>
      </div>
      <div class="table-header">
        <span class="header-custom">
          {{ table_header }}
        </span>
      </div>
      <div class="table-header-drop">
        <div class="header-drop-title-1">EVALUACIÓN</div>
        <div class="header-drop-title-2">INSTRUMENTO</div>
      </div>
      <MatterGradesPerSectionTable
        :key="table_key"
        :students_data_case="students_data_case"
        :chartdata="chartdata[students_data_case]"
        :items="items"
        :fields="fields"
        :column_name="column_name"
      ></MatterGradesPerSectionTable>
      <template v-if="!chartdata[students_data_case].datasets.length">
        <div class="empty-dataset-alert">
          <b-icon icon="exclamation-circle" class="mr-1"></b-icon> No hay datos
          para mostrar.
        </div>
      </template>
      <template v-if="chartdata[students_data_case].extra_data">
        <div class="report-ranges-caption mb-2">
          <div class="report-ranges-header">RANGOS DE NOTAS</div>
          <template
            v-for="(grade_range, index) in chartdata[students_data_case]
              .extra_data.grades_ranges"
          >
            <div class="report-range" :key="grade_range.id">
              <span class="range-label">
                {{
                  chartdata[students_data_case].extra_data.grades_ranges[
                    index
                  ][0]
                }}
              </span>
              <div
                :class="{
                  'range-value-red': index == 0,
                  'range-value-yellow': index == 1,
                  'range-value-green': index == 2,
                }"
              >
                {{
                  chartdata[students_data_case].extra_data.grades_ranges[
                    index
                  ][1]
                }}
                -
                {{
                  chartdata[students_data_case].extra_data.grades_ranges[
                    index
                  ][2]
                }}
              </div>
            </div>
          </template>
        </div>
      </template>
    </template>
    <template v-else>
      <div>No hay datos para mostrar.</div>
    </template>
    <template>
      <download-excel
        class="btn btn-primary mt-2"
        :data="itemsXLS"
        :fields="jsonFields"
        worksheet="REPORTE DE NOTAS"
        :name="`Reporte Notas ${$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        )} por ${$getVisibleNames('teaching.section', false, 'Sección')}.xls`"
        :header="headerArray"
        :footer="footerArray"
      >
        Descargar XLS
      </download-excel>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MatterGradesPerSection",
  components: {
    MatterGradesPerSectionTable: () =>
      import("./MatterGradesPerSectionTable.vue"),
  },
  props: ["chartdata", "selectors"],
  data() {
    return {
      section_name: "",
      label_tooltip: "",
      grades_report: true,
      report_thirth: false,
      students_data_status: false,
      students_data_case: 1,
      column_name: "N°",
      // table_header: "REPORTE DE NOTAS ASIGNATURA POR SECCIÓN",
      table_key: 0,
    };
  },
  computed: {
    ...mapGetters({
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
    }),
    table_header() {
      return String(
        "REPORTE DE NOTAS " +
          this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ).toUpperCase() +
          " POR " +
          this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          ).toUpperCase()
      );
    },
    items() {
      let list = [];
      this.chartdata[this.students_data_case].datasets.forEach((elements) => {
        let row = { section: elements.label };
        for (let index = 0; index < elements.data.length; index++) {
          let label = this.chartdata[this.students_data_case].labels[index];
          let data = elements.data[index];
          if (!isNaN(data) && label.includes("PONDERACIÓN") && data != "") {
            data = data + "%";
          }
          row[label] = data;
        }
        list.push(row);
      });
      return list;
    },
    itemsXLS() {
      let list = [];
      this.chartdata[this.students_data_case].datasets.forEach((elements) => {
        let row = { section: elements.label };
        for (let index = 0; index < elements.data.length; index++) {
          let label = this.chartdata[this.students_data_case].labels[index];
          let data = elements.data[index];
          if (!isNaN(data) && label.includes("PONDERACIÓN") && data != "") {
            data = data + "%";
          }
          row[label] = data;
        }
        list.push(row);
      });
      return list;
    },
    fields() {
      let list = [];
      for (
        let index = 0;
        index < this.chartdata[this.students_data_case].labels.length;
        index++
      ) {
        let column_header =
          this.chartdata[this.students_data_case].labels[index];
        list.push(column_header);
      }
      return list;
    },
    footers() {
      let list = [];
      for (
        let index = 0;
        index < this.chartdata[this.students_data_case].labels.length;
        index++
      ) {
        let counter = 0;
        this.items.forEach((section) => {
          counter +=
            section[this.chartdata[this.students_data_case].labels[index]];
        });
        list.push(counter);
      }
      return list;
    },
    jsonFields() {
      let json_fields = { "N°": "section" };
      this.fields.forEach((label) => {
        json_fields[label] = label;
      });
      return json_fields;
    },
    jsonFooter() {
      let json_footer = { section: "Totales" };
      for (
        let index = 0;
        index < this.chartdata[this.students_data_case].labels.length;
        index++
      ) {
        const label = this.chartdata[this.students_data_case].labels[index];
        const data = this.footers[index];
        json_footer[label] = data;
      }
      return json_footer;
    },
  },
  methods: {
    headerArray() {
      let list = "";
      list +=
        "<tr>" +
        `<th colspan='${
          this.chartdata[this.students_data_case].labels.length + 1
        }' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
        this.table_header +
        "</th>" +
        "</tr>";
      if (this.selectors.selected_campuses.length > 0) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "CAMPUS: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_campuses[0].name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_egress_profiles) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil De Egreso"
          ).toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_egress_profiles[0].professional_title.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_periods) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames("mesh.period", false, "Período").toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_periods[0].name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_levels) {
        list +=
          "<tr>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "NIVEL: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle; text-align: center'>" +
          "<b>" +
          this.selectors.selected_levels[0].name +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_matters.length > 0) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ).toUpperCase() +
          ": " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_matters[0].matter_name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_sections.length > 0) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          ).toUpperCase() +
          ": " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_sections[0].name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      return list;
    },
    footerArray() {
      let list = [];
      list = list.concat(
        "<tr>" +
          `<th colspan='8' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
          "RANGOS DE NOTAS" +
          "</th>" +
          "</tr>" +
          "<tbody>" +
          "<tr>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "INSUFICIENTE: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important; text-align: center'>" +
          "<b>" +
          "1.0 - 3.9" +
          "</b>" +
          "</td>" +
          "<td colspan='1' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "SUFICIENTE: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle; text-align: center'>" +
          "<b>" +
          "4.0 - 5.5" +
          "</b>" +
          "</td>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "DESTACADO: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important; text-align: center'>" +
          "<b>" +
          "5.5 - 7.0" +
          "</b>" +
          "</td>" +
          "</tr>" +
          "</tbody>"
      );
      return list;
    },
    headerTooltip(data_label) {
      let tooltip_content = "";
      this.chartdata[this.students_data_case].labels.forEach((label) => {
        if (label[0] == data_label) {
          tooltip_content = label[1];
        }
      });
      return tooltip_content;
    },
    headerWeighing(data_label) {
      let tooltip_content = "";
      this.chartdata[this.students_data_case].labels.forEach((label) => {
        if (label[0] == data_label) {
          tooltip_content = label[2];
        }
      });
      return tooltip_content;
    },
    changeStudentsData(value) {
      this.$emit("studentDataChanged", value);
    },
  },
  mounted() {
    // this.students_data_status = this.students_data_changed;
    if (this.section) {
      this.$store
        .dispatch(names.FETCH_SECTION, this.section)
        .then((response) => {
          this.section_name = response.name;
        });
    } else {
      this.section_name = "SIN SELECCIONAR";
    }
  },
  watch: {
    // students_data_changed() {
    //   if (!this.students_data_status) this.students_data_case = 1;
    //   setTimeout(() => {
    //     this.students_data_status = this.students_data_changed;
    //   }, 500);
    // },
    students_data_status() {
      this.students_data_case = 0;
      if (!this.students_data_status) this.students_data_case = 1;
      setTimeout(() => {
        this.table_key += 1;
      }, 500);
    },
  },
};
</script>

<style scoped>
.table-container {
  scroll-behavior: auto;
}
.table-header {
  background: var(--primary-color);
  font-weight: bold;
  padding: 0.2em;
}
.change-data-btn {
  display: flex;
  margin-left: auto !important;
  margin-right: 0 !important;
  float: right;
  font-weight: bold;
}
.header-custom {
  margin-right: -20%;
}
.table-header-drop {
  display: flex;
  background-color: var(--primary-hover-color);
  font-weight: bold;
  font-size: 11pt;
  border: 1px solid #dee2e6;
  border-bottom: none;
}
.header-drop-title-1,
.header-drop-title-2 {
  padding: 0.3rem 0;
}
.header-drop-title-1 {
  width: 47.05%;
  min-width: 17rem;
  border-right: 1px solid #dee2e6;
}
.header-drop-title-2 {
  width: 52.95%;
}
.table-container >>> td:first-of-type,
.table-container >>> th:first-of-type {
  width: 4% !important;
}
.table-container >>> td,
.table-container >>> th {
  width: 10.75% !important;
}
.table-container >>> .custom-header {
  background: var(--primary-hover-color) !important;
}
.table-container >>> .table thead th {
  vertical-align: middle !important;
}

.report-ranges-caption {
  width: 100%;
  border: 1px solid var(--primary-hover-color);
}
.report-ranges-header {
  text-align: left;
  padding: 0.5em 1em;
  padding-left: 2em;
  background: var(--primary-hover-color);
  color: var(--secondary-font-color);
  font-weight: bold;
}
.report-range {
  display: flex;
  margin-left: 0;
  padding: 0.5em 4em;
}
.range-label {
  text-align: left;
  width: 15%;
}
.report-range .range-value-red {
  background: #f19292;
  width: 15%;
}
.report-range .range-value-yellow {
  background: #fff1ac;
  width: 15%;
}
.report-range .range-value-green {
  background: #bbeaa6;
  width: 15%;
}
.empty-dataset-alert {
  margin: 2em 0;
  font-size: 13pt;
}
</style>