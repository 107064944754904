var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container"},[(_vm.chartdata[_vm.students_data_case].datasets)?[_c('div',[_c('div',{staticClass:"change-data-btn"},[_vm._v(" Incorporar estudiantes sin contestar instrumento "),_c('b-form-checkbox',{staticClass:"ml-1",attrs:{"switch":""},on:{"change":_vm.changeStudentsData},model:{value:(_vm.students_data_status),callback:function ($$v) {_vm.students_data_status=$$v},expression:"students_data_status"}})],1)]),_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"header-custom"},[_vm._v(" "+_vm._s(_vm.table_header)+" ")])]),_vm._m(0),_c('MatterGradesPerSectionTable',{key:_vm.table_key,attrs:{"students_data_case":_vm.students_data_case,"chartdata":_vm.chartdata[_vm.students_data_case],"items":_vm.items,"fields":_vm.fields,"column_name":_vm.column_name}}),(!_vm.chartdata[_vm.students_data_case].datasets.length)?[_c('div',{staticClass:"empty-dataset-alert"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-circle"}}),_vm._v(" No hay datos para mostrar. ")],1)]:_vm._e(),(_vm.chartdata[_vm.students_data_case].extra_data)?[_c('div',{staticClass:"report-ranges-caption mb-2"},[_c('div',{staticClass:"report-ranges-header"},[_vm._v("RANGOS DE NOTAS")]),_vm._l((_vm.chartdata[_vm.students_data_case]
            .extra_data.grades_ranges),function(grade_range,index){return [_c('div',{key:grade_range.id,staticClass:"report-range"},[_c('span',{staticClass:"range-label"},[_vm._v(" "+_vm._s(_vm.chartdata[_vm.students_data_case].extra_data.grades_ranges[ index ][0])+" ")]),_c('div',{class:{
                'range-value-red': index == 0,
                'range-value-yellow': index == 1,
                'range-value-green': index == 2,
              }},[_vm._v(" "+_vm._s(_vm.chartdata[_vm.students_data_case].extra_data.grades_ranges[ index ][1])+" - "+_vm._s(_vm.chartdata[_vm.students_data_case].extra_data.grades_ranges[ index ][2])+" ")])])]})],2)]:_vm._e()]:[_c('div',[_vm._v("No hay datos para mostrar.")])],[_c('download-excel',{staticClass:"btn btn-primary mt-2",attrs:{"data":_vm.itemsXLS,"fields":_vm.jsonFields,"worksheet":"REPORTE DE NOTAS","name":`Reporte Notas ${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      )} por ${_vm.$getVisibleNames('teaching.section', false, 'Sección')}.xls`,"header":_vm.headerArray,"footer":_vm.footerArray}},[_vm._v(" Descargar XLS ")])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header-drop"},[_c('div',{staticClass:"header-drop-title-1"},[_vm._v("EVALUACIÓN")]),_c('div',{staticClass:"header-drop-title-2"},[_vm._v("INSTRUMENTO")])])
}]

export { render, staticRenderFns }